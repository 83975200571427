var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Групи тарифу ")]),_c('v-spacer'),_c('MainModal',{attrs:{"main":{ component: 'TariffLegacyGroup', title: 'Групи тарифу' },"button":{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' },"item":{
                    flat_name: _vm.flat.full_address,
                    person_hash: _vm.flat.person_hash,
                  }},on:{"updateItemModal":_vm.updateItemModal}})],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.groupHeaders,"items":_vm.tariffGroups,"hide-default-footer":""},on:{"click:row":_vm.openDialog},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('MainModal',{attrs:{"main":{ component: 'TariffLegacyGroup', title: 'Групи тарифу' },"button":{ iconActivator: true, class: 'mr-2', color: 'success', buttonIcon: 'mdi-format-list-numbered', iconSize: 26 },"item":{
                      flat_name: _vm.flat.full_address,
                      person_hash: _vm.flat.person_hash,
                      id: item.id,
                      contract_id: item.contract_id,
                      tariff_group_type: item.tariff_group_type_id,
                      name: item.tariff_group_name,
                      date_start: item.date_start,
                      date_end: item.date_end
                      },"modal":_vm.modal_id === item.id},on:{"updateItemModal":_vm.updateItemModal}})]}},{key:"item.date_start",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")]}},{key:"item.date_end",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_end))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }