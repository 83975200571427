<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280">
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Групи тарифу
        </v-toolbar-title>
        <v-spacer/>
        <MainModal
            :main="{ component: 'TariffLegacyGroup', title: 'Групи тарифу' }"
            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
            :item="{
                      flat_name: flat.full_address,
                      person_hash: flat.person_hash,
                    }"
            @updateItemModal="updateItemModal"
        />
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="groupHeaders"
        :items="tariffGroups"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openDialog"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'TariffLegacyGroup', title: 'Групи тарифу' }"
            :button="{ iconActivator: true, class: 'mr-2', color: 'success', buttonIcon: 'mdi-format-list-numbered', iconSize: 26 }"
            :item="{
                        flat_name: flat.full_address,
                        person_hash: flat.person_hash,
                        id: item.id,
                        contract_id: item.contract_id,
                        tariff_group_type: item.tariff_group_type_id,
                        name: item.tariff_group_name,
                        date_start: item.date_start,
                        date_end: item.date_end
                        }"
            :modal="modal_id === item.id"
            @updateItemModal="updateItemModal"
        />
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | formatDate }}
      </template>
      <template v-slot:item.date_end="{ item }">
        {{ item.date_end | formatDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {
  FETCH_TARIFF_GROUP_BY_LEGACY_FLAT,
  FETCH_TARIFF_GROUP_SELECT
} from "@/store/actions/tariff_group";

export default {
  name: "HWL_TariffGroupsTable",
  components: {MainModal},
  computed: {
    ...mapGetters({
      tariffGroups: 'getTariffLegacyGroup',
      flat: 'getLegacyFlat'
    })
  },
  data() {
    return {
      modal_id: 0,
      groupHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Договір', value: 'contract_name'},
        {text: 'Назва групи', value: 'tariff_group_type_name'},
        {text: 'Початок', value: 'date_start'},
        {text: 'Закінчення', value: 'date_end'},
        {text: 'Автор', value: 'owner_name'},
      ],
    }
  },
  methods: {
    ...mapActions({
      fetchTariffGroups: FETCH_TARIFF_GROUP_BY_LEGACY_FLAT,
      fetchTariffGroupSelect: FETCH_TARIFF_GROUP_SELECT
    }),
    updateItemModal() {
      this.modal_id = 0
    },
    openDialog(row) {
      this.modal_id = row.id
    }
  },
  created() {
    const person_hash = this.$route.params.person_hash
      this.fetchTariffGroups(person_hash)
          .then(res => {
            if (res) {
              this.fetchTariffGroupSelect(person_hash)
            }
          })
  }
}
</script>

<style scoped lang="scss">

</style>